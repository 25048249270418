import { z } from 'zod'
import type { AxiosError } from 'axios'
import rosetta from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'

export function HeadersConAuth(): Record<string, string | number> {
	if (!token.value)
		throw new ErrorAumentado('noHayToken', { origen: 'HeadersConAuth' })
	const headersBase = HeadersBase()
	return {
		...headersBase,
		Authorization: `Bearer ${token.value}`
	}
}

export function HeadersBase(): Record<string, string | number> {
	const { gimnasioID } = useGimnasio()
	const headers: {
		[key: string]: string
	} = {
		'Accept': 'application/json',
		'GoTs-App': contextoApp.appConfig.appID,
		'GoTs-Ambiente': contextoApp.buildConfig.modo,
		'GoTs-Dispositivo': contextoApp.buildConfig.dispositivo,
		'GoTs-Version': contextoApp.buildConfig.version
	}
	if (gimnasioID.value)
		headers['GoTs-Gimnasio'] = gimnasioID.value
	return headers
}

const i18nAxios = rosetta({
	errorDeRed: {
		es: 'Error de red',
		en: 'Network error',
		pt: 'Erro de rede'
	},
	sinRespuesta: {
		es: 'Sin respuesta',
		en: 'No response',
		pt: 'Sem resposta'
	},
	malaConfiguracion: {
		es: 'Mala configuración',
		en: 'Bad configuration',
		pt: 'Má configuração'
	},
	noSePudoConectarConAPI: {
		es: 'No se pudo conectar con API',
		en: 'Could not connect to API',
		pt: 'Não foi possível conectar à API'
	},
	noAutorizado: {
		es: 'No autorizado',
		en: 'Unauthorized',
		pt: 'Não autorizado'
	},
	solicitudRechazada: {
		es: 'Solicitud rechazada',
		en: 'Request rejected',
		pt: 'Solicitação rejeitada'
	}
})

const RespuestaConErrorZod = z.object({
	ok: z.literal(false),
	error: z.string()
})

const appNoAutorizadaRef = ref(false)
export const appNoAutorizada = computed(() => appNoAutorizadaRef.value)

export function ManejadorErroresAxios(error: AxiosError) {
	consolo.log('\n\nManejadorErroresAxios error', error)
	if (error.toString().includes('Network Error')) {
		notificadorEnApp.error({
			titulo: i18nAxios('errorDeRed'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'noSePudoConectarConAPI',
			duracionSegundos: 5,
			icono: 'arcticons:trexrunner',
			iconoMedida: '3rem'
		})
		throw new ErrorAumentado('sinConexion')
	}

	if (!error.response)
		throw new ErrorAumentado('sinRespuesta')

	const errorParseo = RespuestaConErrorZod.safeParse(error.response.data)

	if (!errorParseo.success)
		throw new ErrorAumentado('respuestaConErrorMalParseada', { datos: { responseData: error.response.data } })

	const codigo = errorParseo.data.error

	if (codigo === 'appNoAutorizada')
		appNoAutorizadaRef.value = true

	if (codigo === 'gimNoAutorizado')
		AppAPI.DesconectarGimnasio()

	if (codigo === 'cuentaEliminada')
		UsuarioAPI.CerrarSesion()

	if (codigo === 'usuarioNoRegistrado') {
		const i18n = TrosettaAPI.crear({
			seHaCerradoLaSesion: {
				es: 'Se ha cerrado la sesión',
				en: 'Session has been closed',
				pt: 'A sessão foi encerrada'
			},
			usuarioNoRegistrado: {
				es: 'Usuario no encontrado',
				en: 'User not found',
				pt: 'Usuário não encontrado'
			}
		})
		notificadorEnApp.error({
			titulo: i18n('usuarioNoRegistrado'),
			texto: i18n('seHaCerradoLaSesion'),
			codigo,
			duracionSegundos: 3,
			icono: 'solar:ghost-outline',
			iconoMedida: '3rem'
		})
		UsuarioAPI.CerrarSesion()
		throw new ErrorAumentado('sinConexion')
	}

	return errorParseo.data
}
