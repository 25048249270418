<template lang="pug">
.version.op05.fz07rem.fwm.tar.usn
	.px1ud.flex
		div v{{ contextoApp.buildConfig.version }} #[span(v-for="rol in susRoles") {{ i18n(rol) }}]
</template>

<script lang="ts" setup>
const i18n = TrosettaAPI.crear({
	staff: {
		es: 'Staff',
		en: 'Staff',
		pt: 'Staff'
	},
	coach: {
		es: 'Coach',
		en: 'Coach',
		pt: 'Coach'
	},
	admin: {
		es: 'Admin',
		en: 'Admin',
		pt: 'Admin'
	},
	recepcion: {
		es: 'Recepción',
		en: 'Reception',
		pt: 'Recepção'
	},
	head_coach: {
		es: 'Head Coach',
		en: 'Head Coach',
		pt: 'Head Coach'
	},
	super_admin: {
		es: 'Super Admin',
		en: 'Super Admin',
		pt: 'Super Admin'
	},
	alumno: {
		es: '',
		en: '',
		pt: ''
	}
})

const susRoles = computed(() => unref(roles))
</script>

<style lang="sass" scoped>
@import '@base/sass/comun'
.version
	position: fixed
	z-index: 100
	top: 0
	right: 0
	line-height: 1
	// border: 2px solid red
	padding-top: const(safe-area-inset-top, .5rem)
	padding-top: env(safe-area-inset-top, .5rem)
	+movil
		padding-top: const(safe-area-inset-top, .1rem)
		padding-top: env(safe-area-inset-top, .1rem)
	+tablet
		padding-top: max(const(safe-area-inset-top, .5rem), .5rem)
		padding-top: max(env(safe-area-inset-top, .5rem), .5rem)
</style>
