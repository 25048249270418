<template lang="pug">
	div
		transition
			.giraPorfa(v-if="!esCompu && 600 > alto && ancho > 600")
				.oicono.m.giraRetrato
				.texto {{i18n('giraTuDispositivo')}}
</template>

<script lang="ts" setup>
import { capturarEventoDeApp } from '@base/composables/eventosDeApp';
import DeviceDetector from 'device-detector-js'

const i18n = TrosettaAPI.crear({
	giraTuDispositivo: {
		es: 'Gira tu dispositivo',
		en: 'Rotate your device',
		pt: 'Gire seu dispositivo'
	}
})


function detectarDispositivo() {
	if (typeof window === 'undefined')
		return null
	const detector = new DeviceDetector()
	const dispositivo = detector.parse(window.navigator.userAgent)

	return dispositivo
}

const esCompu = computed(() => {
	const dispositivo = detectarDispositivo()
	return dispositivo?.device?.type === 'desktop'
})

const ancho = ref(1000)
const alto = ref(1000)

function refrescarPantalla() {
	ancho.value = window.innerWidth
	alto.value = window.innerHeight
}

onMounted(() => {
	refrescarPantalla()
	capturarEventoDeApp('windowResize', refrescarPantalla)
})
</script>

<style lang="sass" scoped>
@import "@base/sass/comun"
.giraPorfa
	position: fixed
	z-index: 1000
	&::before
		content: ''
		display: block
		position: absolute
		background-color: var(--bodyFondo)
		opacity: .8
		top: 0
		left: 0
		right: 0
		bottom: 0
	display: flex
	flex-flow: column nowrap
	justify-content: center
	align-items: center
	user-select: none

	.oicono,
	.texto
		z-index: 1
	.oicono
		font-size: 5em
	.texto
		margin-top: 1em
		text-align: center
		max-width: 50vw

	top: 0
	left: 0
	right: 0
	bottom: 0
	opacity: 1
	pointer-events: all
	backdrop-filter: blur(.4em)
	transition: opacity .3s ease
	// +transicionando
	+ausente
		opacity: 0
		pointer-events: none
</style>
