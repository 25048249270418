<template lang="pug">
.UiNotificaciones(:class="{ oscuro: unref(TemaOscuroAPI.activo) }")
	ClientOnly
		transition
			.zonaNotificaciones.colapsable.ffcn.aife(v-if="!lodash.isEmpty(notificacionesAbiertas)")
				transition-group
					UiNotificacion(v-for="(notificacion, id) in notificacionesAbiertas" :key="id" :notificacion="notificacion")
</template>

<script lang="ts" setup>

const notificacionesAbiertas = computed(() => {
	const notificaciones = unref(notificacionesEnApp)
	return lodash.pickBy(notificaciones, n => !n.cerrada)
})

// Seccion para desarrollo y pruebas
const tiempoPorDefecto = 3
function teclasGenerador(event: KeyboardEvent) {
	if (event.code === 'KeyK' && event.altKey) {
		notificadorEnApp.info({
			titulo: 'Información',
			texto: 'Has pulsado las teclas Alt + K',
			codigo: 'infoPorTeclas',
			duracionSegundos: tiempoPorDefecto
		})
	}
	if (event.code === 'KeyS' && event.altKey) {
		notificadorEnApp.exito({
			titulo: 'Éxito',
			texto: 'Has pulsado las teclas Alt + S',
			codigo: 'exitoPorTeclas',
			duracionSegundos: tiempoPorDefecto
		})
	}
	if (event.code === 'KeyW' && event.altKey) {
		notificadorEnApp.atencion({
			titulo: 'Atencion',
			texto: 'Has pulsado las teclas Alt + W',
			codigo: 'atencionPorTeclas',
			duracionSegundos: tiempoPorDefecto
		})
	}
	if (event.code === 'KeyP' && event.altKey) {
		notificadorEnApp.error({
			titulo: 'Error',
			texto: 'Has pulsado las teclas Alt + P',
			codigo: 'errorPorTeclas',
			duracionSegundos: tiempoPorDefecto
		})
	}
}

// Agregar un listener para las teclas
onMounted(() => {
	window.addEventListener('keydown', teclasGenerador)
})
// Quitarlo al desmontar
onUnmounted(() => {
	window.removeEventListener('keydown', teclasGenerador)
})
</script>

<style lang="sass" scoped>
@import '@base/sass/comun'
.UiNotificaciones
	position: fixed
	z-index: 1000
	top: 0
	right: 0
	line-height: 1
	// border: 2px solid red
	padding-top: const(safe-area-inset-top)
	padding-top: env(safe-area-inset-top)
	+movil
		padding-top: const(safe-area-inset-top)
		padding-top: env(safe-area-inset-top)
		left: 0
	+tablet
		padding-top: const(safe-area-inset-top)
		padding-top: env(safe-area-inset-top)
		left: 0
	.zonaNotificaciones
		pointer-events: none
		padding: 2rem
		position: relative
		padding: var(--ud)
		+movil
			border-bottom-left-radius: $radio
			border-bottom-right-radius: $radio

			width: 100%
			left: 0
			right: 0
			backdrop-filter: blur(.5rem)
			&::before
				border-bottom-left-radius: $radio
				border-bottom-right-radius: $radio
				content: ''
				display: block
				+maximizar
				background-color: var(--bodyFondo)
				opacity: .1
			.notificacion
				width: 100%
	.notificacion
		pointer-events: initial
	.notificacion + .notificacion
		margin-top: .5rem
</style>
