import axios from 'axios'
import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'
import { consolo } from '../../composables/consolo'
import { PaqueteHaciaAppZod, type NotificacionAumentada, type PaqueteDesdeApp } from '@comun/notificaciones.types'
import { contextoApp } from '@base/composables/contextoApp'


function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0;i < rawData.length;++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

let notiWorkerRegistrado: ServiceWorkerRegistration | null = null
let pushSubscription: PushSubscription | null = null

export const viaNotiWorker = {
	...emisorEventosObjeto,

	async init() {
		try {

			// const fx = 'viaNotiWorker>init'
			if (!('serviceWorker' in navigator && 'PushManager' in window)) {
				consolo.warn('Push messaging is not supported')
				return
			}
			
			if (!contextoApp.buildConfig.firmaLlavePublica) {
				consolo.warn('No hay llave publica que permita usar notificaciones push en web.')
				return
			}
			navigator.serviceWorker.addEventListener('message', viaNotiWorker.onMessage)

			notiWorkerRegistrado = await navigator.serviceWorker
				.register(`/notiWorker.js?at=${new Date().getTime()}`)
				.catch(error => {
					consolo.error('Service Worker Error', error)
					return null
				})

			if (!notiWorkerRegistrado || !notiWorkerRegistrado.active)
				return
			else consolo.log('Service Worker registrado', notiWorkerRegistrado)


			notiWorkerRegistrado.active.postMessage({ accion: 'recibirNotificacionesPendientes' })
			notiWorkerRegistrado.active.postMessage({ accion: 'recibirMensajesPendientes' })

			const permisoExiste = window.Notification.permission === 'granted'
			this.emit('cambioPermiso', window.Notification.permission === 'granted')
			if (!permisoExiste) return


			pushSubscription = await notiWorkerRegistrado.pushManager.getSubscription()
				.then(function (subscription) {
					if (subscription === null) {
						return notiWorkerRegistrado!.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: urlBase64ToUint8Array(contextoApp.buildConfig.firmaLlavePublica)
						});
					}
					return subscription;
				})
		} catch (error) {
			console.error('Error en viaNotiWorker>init', error)
		}
	},
	async suscribir() {
		const fx = 'viaNotiWorker>suscribir'
		if (!pushSubscription) return

		const miToken = unref(token)
		const headers = miToken ? HeadersConAuth() : HeadersBase()

		const respuesta = await axios({
			url: `${contextoApp.buildConfig.apiUrl}/notificador/webpush/suscribir`,
			method: 'post',
			headers,
			data: { pushSubscription }
		})
			.then(r => r.data)
			.catch(ManejadorErroresAxios)

		consolo.log(fx, 'respuesta', respuesta)

		this.emit('cambioPermiso', window.Notification.permission === 'granted')
	},
	async leerPermiso() {
		return window.Notification.permission === 'granted'
	},
	async pedirPermiso() {
		const permiso = await window.Notification.requestPermission()
		this.emit('cambioPermiso', permiso === 'granted')
		return permiso
	},
	programar(notificacion: NotificacionAumentada) {
		const mensaje: PaqueteDesdeApp = {
			accion: 'programarNotificacion',
			notification: notificacion
		}
		notiWorkerRegistrado?.active?.postMessage(mensaje)
	},
	cancelarTodas() {
		notiWorkerRegistrado?.active?.postMessage({
			accion: 'cancelarTodas'
		})
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMessage(event: Event & { data: any }) {
		const parseoPaquete = PaqueteHaciaAppZod.safeParse(event.data)
		if (parseoPaquete.success) {
			const mensaje = parseoPaquete.data
			consolo.log('Mensaje recibido desde serviceWorker')
			console.log('mensaje', mensaje)

			if (mensaje.accion === 'notificacionRecibida')
				viaNotiWorker.emit('notificacionRecibida', mensaje.data)
			else if (mensaje.accion === 'notiClick')
				viaNotiWorker.emit('notificationClick', mensaje.data)
			else if (mensaje.accion === 'notiClose')
				viaNotiWorker.emit('notificationClose', mensaje.data)
		}
	}
}

