
import { z } from 'zod'
import _ from 'lodash'
import type {
	Gimnasio,
	ExtractoGimnasio,
	PlanConRelacionados,
	ProgramaConRelacionados,
	RegistroDePlanesConRelacionados,
	RegistroDeProgramasConRelacionados
} from '@comun/types'
import { GimnasioZod } from '@comun/types'
import { axios } from '@base/lib/axios'

import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'
import { ErrorAumentado } from '@base/lib/error'

import { ErrorNotificableZod, i18nIconos, i18nTapi } from '../lib/erroresNotificables'

export function GenerarPlanesConRelacionados<ExtGimnasio extends ExtractoGimnasio>(gimnasio: ExtGimnasio) {
	const planes = gimnasio.planes

	const planesRelacionados: RegistroDePlanesConRelacionados = {}
	_.forEach(Object.keys(planes), (planID) => {
		const plan = planes[planID]
		const { programas } = useProgramas()
		const programasDelPlan = _.pick(programas.value, plan.programasIDs)

		const clases = _.pickBy(gimnasio.clases, clase =>
			_.intersection(plan.programasIDs, clase.programasIDs)
		)

		const registroDeProgramasConRelacionados: RegistroDeProgramasConRelacionados
			= {}

		_.forEach(programasDelPlan, (programa, programaID) => {
			const clasesDelPrograma = _.pickBy(clases, clase =>
				_.includes(clase.programasIDs, programaID)
			)
			const programaConRelacionados: ProgramaConRelacionados = {
				...programa,
				clases: clasesDelPrograma
			}
			registroDeProgramasConRelacionados[programaID] = programaConRelacionados
		})

		const planConRelacionados: PlanConRelacionados = {
			...plan,
			programas: registroDeProgramasConRelacionados,
			clases
		}
		planesRelacionados[planID] = planConRelacionados
	})

	return planesRelacionados
}

export const planes = computed(() => {
	const planesRelacionados: Record<string, PlanConRelacionados> = {}
	if (!gimnasio.value)
		return planesRelacionados
	return GenerarPlanesConRelacionados(gimnasio.value)
})

const consoloRaiz = 'Lib TAPI Gimnasio'
const consoloColor = 'color: #46C7C7'

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }

// * Estados

const RefGimnasio = ref<Gimnasio | null>(null)

export const gimnasio = computed(() => RefGimnasio.value)
export const gimnasioBMID = computed(() => RefGimnasio.value?.bmID)
export const perfilDelCentro = computed(() => RefGimnasio.value?.perfilDelCentro)
export const caracteristicasGimnasio = computed(
	() => RefGimnasio.value?.caracteristicas
)

export const traduccionesPersonalizadas = computed(() => {
	const gim = unref(gimnasio)
	if (!gim || !gim.caracteristicas.traduccionesPersonalizadas)
		return {}
	return gim.traduccionesPersonalizadas
})

export const clases = computed(() => {
	const g = unref(gimnasio)
	if (!g)
		return null
	return g.clases
})


export function useGimnasio() {
	return {
		gimnasioID,
		gimnasioBMID,
		gimnasio,
		refrescandoGimnasio,

		perfilGimnasio: perfilDelCentro,
		participantes,
		refrescandoParticipantes,
		traduccionesPersonalizadas,

		planes: unref(planes),
		clases: unref(clases),
	}
}

// API

const refrescandoGimnasioRef = ref(false)
export const refrescandoGimnasio = computed(() => unref(refrescandoGimnasioRef))

async function ObtenerGimnasio(): Promise<boolean> {
	const fx = 'ObtenerGimnasio'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	
	const gimID = unref(gimnasioID)
	consolo.log('gimID', gimID)
	try {
		if (!gimID) {
			consolo.warn(fx, 'no hay gimnasioID')
			return false
		}
		if (refrescandoGimnasioRef.value) {
			consolo.warn(fx, 'ya se está refrescando')
			return false
		}
		const ruta = `${contextoApp.buildConfig.apiUrl}/boxmagic/gimnasio/leer`
		if (!token.value) {
			consolo.warn(fx, 'no hay token')
			throw new ErrorAumentado('noHayToken', { origen: fx })
		}

		refrescandoGimnasioRef.value = true
		const respuesta = await axios({
			url: ruta,
			headers: HeadersConAuth()
		}).then(r => r.data)
			.catch(ManejadorErroresAxios)

		// consolo.log(fx, 'r', respuesta)

		const respuestaMiGimnasioError = z.object({
			ok: z.literal(false),
			error: ErrorNotificableZod
		})
		const respuestaMiGimnasioOk = z.object({
			ok: z.literal(true),
			gimnasio: GimnasioZod
		})

		const parserMiGimnasio = z.discriminatedUnion('ok', [
			respuestaMiGimnasioOk,
			respuestaMiGimnasioError
		])

		const parseoGimnasio = parserMiGimnasio.safeParse(respuesta)
		if (!parseoGimnasio.success) {
			// Reportar
			throw new ErrorAumentado('ErrorDeParseo', {
				datos: {
					parser: 'parseoGimnasio'
				},
				error: parseoGimnasio.error
			})
		}

		if (parseoGimnasio.data.ok === false) {
			const { error: errorID } = respuestaMiGimnasioError.parse(respuesta)

			notificadorEnApp.error({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
			if (errorID === 'noPuedeOperarEnEsteGimnasio') {
				//
				// UsuarioAPI.CerrarSesion()
				emisorEventos.emit('cierreSesion')
			}

			return false
		}
		const { gimnasio } = respuestaMiGimnasioOk.parse(respuesta)
		consolo.log('gimnasio seteado', gimnasio)
		RefGimnasio.value = gimnasio

		await new Promise(requestAnimationFrame)
		await nextTick()

		emisorEventos.emit('gimnasio', gimnasio)
		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		refrescandoGimnasioRef.value = false
		consolo.groupEnd()
	}
}


async function IntegrarGimnasio(posibleGimnasio: Gimnasio): Promise<boolean> {
	const fx = 'IntegrarGimnasio'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const parseoGimnasio = GimnasioZod.safeParse(posibleGimnasio)
		if (!parseoGimnasio.success) {
			// Reportar
			throw new ErrorAumentado('ErrorDeParseo', {
				datos: {
					parser: 'parseoGimnasio'
				},
				error: parseoGimnasio.error
			})
		}

		RefGimnasio.value = parseoGimnasio.data

		await new Promise(requestAnimationFrame)
		await nextTick()

		emisorEventos.emit('gimnasio', gimnasio)
		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		refrescandoGimnasioRef.value = false
		consolo.groupEnd()
	}
}
export const GimnasioAPI = {
	...emisorEventos,
	IntegrarGimnasio,
	ObtenerGimnasio,
	Limpiar() {
		const fx = 'Limpiar'
		consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
		RefGimnasio.value = null
	}
}


