<template lang="pug">
div.appRoot.usn
	//- p Tu navegador no tiene JavaScript activado, pero este contenido se mostrará igualmente
	NuxtLayout.paginaWrap.pagina(name="default")
		NuxtPage(:keepAlive="true")

	UiVersion
	UiModales
	BloqueoOrientacion
	UiNotificaciones
</template>

<script lang="ts" setup>
onMounted(() => {
	TemaOscuroAPI.propagarTema()
	setTimeout(() => TemaOscuroAPI.propagarTema(), 300)
	setTimeout(() => TemaOscuroAPI.propagarTema(), 1200)
	setTimeout(() => TemaOscuroAPI.propagarTema(), 2000)
})
</script>

<style lang="sass" scoped>
html:not(.claro):not(.oscuro) .invisibleEnCarga
	visibility: hidden
	transition: all .3s ease .3s
.dev
	position: fixed
	top: 0
	left: 0
	margin: 1rem
	background-color: #333
	color: #999

// Modal
body.afectadoPorModal .appRoot
	touch-action: none
</style>
