import { z } from 'zod'
import dayjs from './fechas'

function preprocesadorFecha(v: any): Date | undefined {
	if (v === undefined || v === null)
		return undefined

	if (dayjs.isDayjs(v)) {
		return dayjs(v).toDate()
	}
	else if (v instanceof Date) {
		if (!Number.isNaN(v.getTime()))
			return v
	}
	else if (typeof v === 'string') {
		const fecha = new Date(v)
		if (fecha instanceof Date) {
			if (!Number.isNaN(fecha.getTime()))
				return fecha
			console.log('preprocesadorFecha, es string pero no logra ser parseado', typeof v, v)
		}
	}

	// console.log('preprocesadorFecha, es OTRO', typeof v, v)
	return v
}

export const FechaZod = z.preprocess(v => preprocesadorFecha(v), z.date())