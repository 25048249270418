
export default defineNuxtPlugin((nuxtApp) => {
	// * Sólo en cliente
	if (!import.meta.client) return

	nuxtApp.hook('app:mounted', () => {
		ProcesadorDeEventos()

		
		AppAPI.init()
	})
})
