import type { Dayjs } from 'dayjs'
export type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import calendar from 'dayjs/plugin/calendar'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import type { Duration } from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import esp from 'dayjs/locale/es-mx.js'
import eng from 'dayjs/locale/en.js'
import por from 'dayjs/locale/pt-br.js'
import minMax from 'dayjs/plugin/minMax'

// import consolo from './consolo'

import { idiomaVisible } from '../lib/rosetta'
import type { IdiomasDisponibles } from '../lib/rosetta'
import { ErrorAumentado } from '../lib/error'

dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale('es-mx')

declare module 'dayjs' {
	function range(start: Dayjs, end: Dayjs): Dayjs[]
	function mostrarRangoFechas(inicio: Date, fin: Date): string
	function mostrarFecha(fecha: Dayjs): string
	function mostrarDiaEnContexto(fecha: Dayjs): string
	function calendario(): string
	function fechaContextual(fecha: Dayjs): string
	function fechayHoraContextual(fecha: Dayjs): string
	function cambiarIdiomaAlDeRosetta(idiomaVisible: IdiomasDisponibles): void
}

dayjs.range = (start: Dayjs, end: Dayjs): Dayjs[] => {
	const range = []
	for (let i = start;i <= end;i = i.add(1, 'day'))
		range.push(i)

	return range
}

dayjs.mostrarRangoFechas = (inicio: Date, fin: Date): string => {
	const elInicio = dayjs(inicio)
	const elFin = dayjs(fin)
	if (!elInicio || !elFin)
		throw new ErrorAumentado('Fail mostrarRangoFechas, falta inicio o fin', { datos: { inicio: elInicio, fin: elFin } })
	const ahora = dayjs()

	if (elInicio.isSame(elFin, 'day'))
		return `${elInicio.format('D MMM.')}`

	if (elInicio.isSame(elFin, 'month')) {
		if (ahora.isSame(elFin, 'year'))
			return `${elInicio.format('D')} - ${elFin.format('D MMM.')}`

		return `${elInicio.format('D')} - ${elFin.format('D MMM. YYYY')}`
	}
	if (elInicio.isSame(elFin, 'year')) {
		if (ahora.isSame(elFin, 'year'))
			return `${elInicio.format('D MMM.')} - ${elFin.format('D MMM.')}`

		return `${elInicio.format('D MMM.')} - ${elFin.format('D MMM. YYYY')}`
	}

	return `${elInicio.isSame(ahora, 'year')
		? elInicio.format('D MMM.')
		: elInicio.format('D MMM. YYYY')
		} - ${elFin.isSame(ahora, 'year') ? elFin.format('D MMM') : elFin.format('D MMM. YYYY')
		}`
}

dayjs.mostrarFecha = (fecha: Dayjs): string => {
	const hoy = dayjs()

	if (fecha.isSame(hoy, 'day'))
		return fecha.format('H:mm a')

	if (fecha.isSame(hoy, 'month'))
		return fecha.format('dddd D, H:mm a')

	if (fecha.isSame(hoy, 'year'))
		return fecha.format('dddd D MMM., H:mm a')

	return fecha.format('dddd D MMM. YYYY, H:mm a')
}


dayjs.mostrarDiaEnContexto = (fecha: Dayjs): string => {
	const contextoEN = {
		sameDay: '[today]',
		nextDay: '[tomorrow]',
		nextWeek: 'dddd',
		lastDay: '[yesterday]',
		lastWeek: '[last] dddd',
		sameElse: 'dddd D'
	}
	const contextoES = {
		sameDay: '[hoy]',
		nextDay: '[mañana]',
		nextWeek: '[próximo] dddd',
		lastDay: '[ayer]',
		lastWeek: 'dddd [pasado]',
		sameElse: 'dddd D'
	}

	const contextoPT = {
		sameDay: '[hoje]',
		nextDay: '[amanhã]',
		nextWeek: 'dddd',
		lastDay: '[ontem]',
		lastWeek: '[último] dddd',
		sameElse: 'dddd D'
	}

	const idiomaID: IdiomasDisponibles = unref(idiomaVisible) || 'es'
	return fecha.calendar(
		null,
		{
			es: contextoES,
			en: contextoEN,
			pt: contextoPT
		}[idiomaID]
	)
}

export const localeActivo = computed(() => {
	const idiomaID = unref(idiomaVisible)
	if (idiomaID === 'en')
		return 'en'
	if (idiomaID === 'pt')
		return 'pt-br'
	else return 'es-mx'
})

function cambiarIdiomaAlDeRosetta(idiomaVisible: IdiomasDisponibles) {
	switch (idiomaVisible) {
		case 'en':
			dayjs.locale(eng)
			break
		case 'pt':
			dayjs.locale(por)
			break
		default:
			dayjs.locale(esp)
	}
}

watch(idiomaVisible, (idiomaID: IdiomasDisponibles) => {
	if (!idiomaID)
		return
	cambiarIdiomaAlDeRosetta(idiomaID)
}, { immediate: true })

export function fechaContextual(fecha: Dayjs) {
	const contextoEN = {
		sameDay: '[Today at] h:mm a',
		nextDay: '[Tomorrow at] h:mm a',
		nextWeek: 'dddd [at] h:mm a',
		lastDay: '[Yesterday at] h:mm a',
		lastWeek: '[Last] dddd [at] h:mm a',
		sameElse: 'dddd D MMM. YYYY'
	}
	const contextoES = {
		sameDay: '[Hoy a las] h:mm a',
		nextDay: '[Mañana a las] h:mm a',
		nextWeek: 'dddd [a las] h:mm a',
		lastDay: '[Ayer a las] h:mm a',
		lastWeek: '[El pasado] dddd [a las] h:mm a',
		sameElse: 'dddd D MMM. YYYY'
	}

	const contextoPT = {
		sameDay: '[Hoje às] h:mm a',
		nextDay: '[Amanhã às] h:mm a',
		nextWeek: 'dddd [às] h:mm a',
		lastDay: '[Ontem às] h:mm a',
		lastWeek: '[Último] dddd [às] h:mm a',
		sameElse: 'dddd D MMM. YYYY'
	}

	const idiomaID: IdiomasDisponibles = unref(idiomaVisible) || 'es'
	return fecha.calendar(
		null, {
			es: contextoES,
			en: contextoEN,
			pt: contextoPT
		}[idiomaID]
	)
}

export function fechayHoraContextual(fecha: Dayjs) {
	const contextoEN = {
		sameDay: '[Today,] h:mm a',
		nextDay: '[Tomorrow,] h:mm a',
		nextWeek: 'dddd, h:mm a',
		lastDay: '[Yesterday,] h:mm a',
		lastWeek: '[Last] dddd, h:mm a',
		sameElse: 'dddd D MMM. YYYY, h:mm a'
	}
	const contextoES = {
		sameDay: '[Hoy] h:mm a',
		nextDay: '[Mañana,] h:mm a',
		nextWeek: 'dddd, h:mm a',
		lastDay: '[Ayer,] h:mm a',
		lastWeek: '[El pasado] dddd, h:mm a',
		sameElse: 'dddd D MMM. YYYY, h:mm a'
	}

	const contextoPT = {
		sameDay: '[Hoje,] h:mm a',
		nextDay: '[Amanhã,] h:mm a',
		nextWeek: 'dddd, h:mm a',
		lastDay: '[Ontem,] h:mm a',
		lastWeek: '[Último] dddd, h:mm a',
		sameElse: 'dddd D MMM. YYYY, h:mm a'
	}
	const idiomaID: IdiomasDisponibles = unref(idiomaVisible) || 'es'
	return fecha.calendar(
		null,
		{
			es: contextoES,
			en: contextoEN,
			pt: contextoPT
		}[idiomaID]
	)
}

dayjs.fechaContextual = fechaContextual
dayjs.fechayHoraContextual = fechayHoraContextual
dayjs.cambiarIdiomaAlDeRosetta = cambiarIdiomaAlDeRosetta


export type { Duration }
export { dayjs }

const almacenDeTiempo = {
	ahora: ref(dayjs()),
	hoy: ref(dayjs().startOf('day')),
	timezone: ref(dayjs.tz.guess())
}

export const reloj = computed(() => unref(almacenDeTiempo.ahora))
export const hoy = computed(() => unref(almacenDeTiempo.hoy))
export const timezoneActual = computed(() => unref(almacenDeTiempo.timezone))

function ejecutarActualizacion() {
	if (typeof window === 'undefined') return
	// console.log('fechas actualizar')

	window.requestAnimationFrame(() => {
		almacenDeTiempo.ahora.value = dayjs()
		if (!almacenDeTiempo.hoy.value.isSame(almacenDeTiempo.ahora.value, 'day'))
			almacenDeTiempo.hoy.value = almacenDeTiempo.ahora.value.startOf('day')

		const proxMomento = dayjs().add(1, 'seconds').set('millisecond', 0)
		setTimeout(() => {
			actualizar()
		}, proxMomento.diff(almacenDeTiempo.ahora.value))
	})
}

const actualizar = debounce(() => {
	ejecutarActualizacion()
}, 900, { leading: true, trailing: false })


ejecutarActualizacion()
