import { stringify as StringifyQS } from 'qs'
import { Browser } from '@capacitor/browser'

import emisorEventosObjeto from '../lib/emisorEventosObjeto'
import type { Monedas } from '@comun/zodComun'

const enfriandoCambioDeRutaRef = ref(false)

export const enfriandoCambioDeRuta = computed(() => unref(enfriandoCambioDeRutaRef))

const bus = { ...emisorEventosObjeto }
bus.on('rutaCambiada', (para: unknown) => {
	consolo.log('rutaCambiada', 'para', para, 'bloqueado')
	enfriandoCambioDeRutaRef.value = true
	setTimeout(() => {
		consolo.log('rutaCambiada', 'para', para, 'liberado')
		enfriandoCambioDeRutaRef.value = false
	}, 400)
})

export const colaURL = StringifyQS

export function urlAgregarQueryParams(url: string, datos: Record<string, string | number>) {
	const cola = StringifyQS(datos)
	const urlCompleta = `${url}?${cola}`
	return urlCompleta
}

export function monetizado(monto: number | string, moneda: Monedas = 'CLP') {
	monto = Number(monto)
	if (Number.isNaN(monto))
		return undefined

	if (monto === 0)
		return ' Gratis'

	if (!monto)
		return null

	if (monto <= 0)
		return 'ERRNEG'

	if (moneda === 'USD')
		monto = monto.toFixed(2)
	return monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function capitalizar(texto: string) {
	if (texto.length === 0)
		return ''
	const str = String(texto)
	return str.substring(0, 1).toUpperCase() + str.substring(1)
}

export function detectorToque(_event: TouchEvent) {
	consolo.log('detectorToque', event)
}

export function abrirEnNavegador(url: string) {
	try {
		const ventanaObservable = {
			...emisorEventosObjeto
		}
		Browser.addListener('browserPageLoaded', () => {
			ventanaObservable.emit('cargada')
		})
		Browser.addListener('browserFinished', () => {
			ventanaObservable.emit('cerrada')
			Browser.removeAllListeners()
		})
		return ventanaObservable
	}
	finally {
		// requestAnimationFrame y continuar
		new Promise(resolve => setTimeout(resolve, 50))
			.then(() => {
				Browser.open({ url, presentationStyle: 'popover' })
			})
	}
}

export async function proximoFrame() { return new Promise(requestAnimationFrame) }

export async function esperar(ms: number) { return new Promise(resolve => setTimeout(resolve, ms)) }